<template>
    <div @click="makeExcel">
        <div class="icon download-excel-icon" />
        <div>下載Excel</div>
    </div>
</template>

<script>
import _ from 'lodash'
// import XLSXStyle from 'xlsx-style'

export default {
    name: 'Excel',
    props: {
        fileName: {
            type: String
        },
        columns: {
            type: Array
        },
        rows: {
            type: Array
        },
        filters: {
            type: Object
        },
        infos: {
            type: Array,
            default: function () {
                return []
            }
        },
        descriptions: {
            type: Array,
            default: function () {
                return []
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        merge: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
        makeExcel: async function () {
            // 宣告 Workbook Object
            if (this.disabled) return
            const mainSheetName = this.fileName
            const filterSheetName = '查詢條件'
            const descSheetName = '備註'
            const infoSheetName = '說明資訊'
            const workbook = {
                SheetNames: [mainSheetName],
                Sheets: {
                    [mainSheetName]: this.mainWorkSheet
                }
            }
            if (this.filters) {
                workbook.Sheets[filterSheetName] = this.filterWorkSheet
                workbook.SheetNames.push(filterSheetName)
            }

            if (this.descriptions.length > 0) {
                workbook.Sheets[descSheetName] = this.descriptionWorkSheet
                workbook.SheetNames.push(descSheetName)
            }

            if (this.infos.length > 0) {
                workbook.Sheets[infoSheetName] = this.infoWorkSheet
                workbook.SheetNames.push(infoSheetName)
            }

            // 設定檔案形式(這邊請避免使用 xlsx 去 write, 下載結束會發現沒有樣式出來)
            const workbookOut = await import('xlsx-style').then(
                (xlsx) =>
                    xlsx.write(workbook, {
                        bookType: 'xlsx',
                        type: 'binary'
                    }) //base64)
            )
            const blob = new Blob([this.stringToArrayBuffer(workbookOut)], {
                type: 'application/octet-stream'
            }) // binary

            this.downloadHelper(this.fileName, blob)
            this.$setGaEvent('downloadExcel', 'click-TableTool')
            this.recordBingo()
        },
        downloadHelper(fileName, blob) {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${fileName}.xlsx`
            link.click()
        },
        stringToArrayBuffer: function (s) {
            const buf = new ArrayBuffer(s.length) // 記憶體位置
            const view = new Uint8Array(buf) // view 記憶體位置
            for (let i = 0; i !== s.length; ++i)
                view[i] = s.charCodeAt(i) & 0xff
            return buf // ArrayBuffer (位置)
        },
        letterToIndex: function (str) {
            let out = 0,
                len = str.length,
                pos = len
            while (--pos > -1) {
                out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - 1 - pos)
            }
            out--
            return out
        },
        IndexToLetter: function (num) {
            let letters = ''
            while (num >= 0) {
                letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
                num = Math.floor(num / 26) - 1
            }
            return letters
        },
        transformDetail: function (rows, deep = 0) {
            const nextDeep = deep + 1
            return rows
                .filter((row) => !row.className?.hide)
                .reduce((memo, row) => {
                    // 只輸出要的欄位，否則json_to_sheet會全轉
                    memo.push(
                        this.columnKeys.reduce((memo, key) => {
                            memo[key] = row[key]?.unit
                                ? `${row[key]?.value}${row[key]?.unit}` //有帶單位屬性的row將value和unit組合輸出
                                : row[key]
                            memo.deep = deep
                            memo.className = row.className || {}
                            return memo
                        }, {})
                    )
                    // 攤平階層的資資料
                    if (row._detail) {
                        memo.push(
                            ...this.transformDetail(row._detail, nextDeep)
                        )
                    }
                    return memo
                }, [])
        },
        extractHtmlContent: function (value) {
            const span = document.createElement('span')
            span.innerHTML = value
            return (span.textContent || span.innerText).replace(/ /g, '')
        },
        recordBingo: function () {
            const routeName = this.$route.name
            if (routeName === 'Award') {
                const contestId = this.filters?.contestId?.value
                const bingoIdList = [101, 102, 103] //獎項查詢_HGA/LGA/MDRT競賽
                if (bingoIdList.includes(Number(contestId))) {
                    const relationMode = this.filters?.relationMode?.label
                    const contestName = this.filters?.contestId?.label

                    const data = {
                        pageName: '獎項查詢',
                        action: `${contestName}_${relationMode}_下載Excel`
                    }
                    this.$recordBingo(data)
                }
            }

            if (routeName === 'ReportContest') {
                const contestId = this.filters?.contestId?.value

                if (String(contestId) == '6') {
                    const relationMode = this.filters?.relationMode?.label
                    const contestLabel = this.filters?.contestId?.label

                    const data = {
                        pageName: '競賽業績查詢',
                        action: `${contestLabel}_${relationMode}_下載Excel`
                    }
                    this.$recordBingo(data)
                }
            }
        }
    },
    computed: {
        excelColumns: function () {
            return this.columns.reduce((memo, column) => {
                if (column.isExcelHide) return memo
                if (column.alias) {
                    Object.keys(column.alias).forEach((alias) => {
                        const newColumn = column.alias[alias]
                        newColumn.key = alias
                        newColumn.isVisible = column.isVisible
                        memo.push(newColumn)
                    })
                } else {
                    memo.push(column)
                }
                return memo
            }, [])
        },
        columnKeys: function () {
            return this.excelColumns.map((column) => column.key)
        },
        columnsLabels: function () {
            return this.excelColumns.reduce((memo, column) => {
                memo[column.key] = column.label
                return memo
            }, {})
        },
        mainWorkSheet: function () {
            const rows = this.transformDetail(_.cloneDeep(this.rows))

            // 自訂表頭
            const dataset = [this.columnsLabels, ...rows]

            const endLetter = this.IndexToLetter(this.columnKeys.length)
            const wsInit = {
                '!ref': `A1:${endLetter}${dataset.length}`
            }

            const maxWidth = {}
            const worksheet = dataset.reduce((ws, row, rowIndex) => {
                this.columnKeys.forEach((colKey, colIndex) => {
                    const colLetter = this.IndexToLetter(colIndex)
                    const cellKey = `${colLetter}${rowIndex + 1}`
                    const colSettings = this.excelColumns
                    const colSetting = colSettings[colIndex]
                    const isHeaderCell = rowIndex === 0
                    // Cell 樣式
                    const fill = {}
                    const font = {}
                    const border = {}
                    const alignment = {}
                    const normalBorder = {
                        style: 'hair',
                        color: { rgb: 'd4d4d4' }
                    }
                    const blackBold = {
                        style: 'medium',
                        color: { rgb: '000000' }
                    }
                    const isNullOrUndefined = !row[colKey] && row[colKey] !== 0
                    let cell = isNullOrUndefined ? '' : row[colKey]
                    let cellAlign

                    /* *** Value處理  *** */
                    // 資料通常有兩種格式：(1)值 (2)object包html、position
                    if (typeof cell === 'object') {
                        // 保留文字位置
                        if (cell.position) cellAlign = cell.position
                        // 轉換Html格式
                        if (cell.value)
                            cell = this.extractHtmlContent(cell.value)
                    }
                    // 清除HTML空白格
                    if (typeof cell === 'string') {
                        cell = cell.replace(/&nbsp;/g, ' ')
                    }
                    // 階層縮排
                    if (colSetting.isFixed) {
                        cell = '    '.repeat(row.deep) + cell
                    }
                    /* *** Cell樣式  ***  */
                    // 表頭樣式
                    if (isHeaderCell) {
                        fill.patternType = 'solid'
                        fill.fgColor = { rgb: 'd3d3d3' }
                        font.bold = true
                    }
                    // 網頁上隱藏的欄位樣式 補上底色
                    if (!colSetting.isVisible) {
                        fill.patternType = 'solid'
                        fill.fgColor = { rgb: 'eaddca' }
                        border.left = normalBorder
                        border.right = normalBorder
                        border.top = normalBorder
                        border.bottom = normalBorder
                    }
                    // 合計列樣式
                    if (row.className && row.className['sum-row']) {
                        fill.fgColor = {
                            rgb: colSetting.isVisible ? 'DAE4C0' : 'D7C3AC'
                        }
                        border.left = normalBorder
                        border.right = normalBorder
                        border.top = normalBorder
                        border.bottom = {
                            style: 'double',
                            color: { rgb: '000000' }
                        }
                    }
                    // 文字對齊樣式
                    alignment.horizontal = cellAlign || colSetting.position
                    alignment.vertical = 'center'
                    // 右邊分隔線
                    border.right = colSetting.divider ? blackBold : undefined
                    // 畫線底樣式
                    if (row.className && row.className['line-bottom']) {
                        border.bottom = blackBold
                    }
                    if (row.className && row.className['line-yellow-bottom']) {
                        border.bottom = {
                            style: 'medium',
                            color: { rgb: 'ffd700' }
                        }
                    }
                    // 外框線樣式
                    const edge = {
                        left: colIndex === 0,
                        right: colIndex === colSettings.length - 1,
                        top: rowIndex === 0,
                        bottom: rowIndex === dataset.length - 1
                    }
                    if (edge.left) border.left = blackBold
                    if (edge.right) border.right = blackBold
                    if (edge.top) border.top = blackBold
                    if (edge.bottom) border.bottom = blackBold
                    // 樣式輸出前先把空的Object移除
                    let styles = { fill, font, alignment, border }
                    Object.keys(styles).forEach((key) => {
                        if (Object.keys(styles[key]).length === 0)
                            delete styles[key]
                    })

                    /* 輸出Cell */
                    ws[cellKey] = {
                        t: typeof cell === 'number' ? 'n' : 's',
                        v: cell,
                        s: styles
                    }

                    /* 數字加入千分位格式 */
                    if (ws[cellKey].t === 'n') {
                        if (ws[cellKey].v % 1 === 0) {
                            ws[cellKey].z = '#,##0'
                        } else {
                            ws[cellKey].z = '#,##0.#############'
                        }
                    }

                    /* 計算此欄最大寬度 (自適應寬度) */
                    const valStr = cell?.toString() || ''
                    const doubleWord =
                        valStr.match(/[\u4e00-\u9fa5]/g)?.length || 0
                    const singleWord = valStr.length - doubleWord
                    const wordWidth = doubleWord * 2 + singleWord + 4 // 4 是用來 padding
                    if (
                        maxWidth[colLetter] < wordWidth ||
                        !maxWidth[colLetter]
                    ) {
                        maxWidth[colLetter] = wordWidth
                    }
                })
                return ws
            }, wsInit)

            const wsCols = Object.values(maxWidth).map((wch) => {
                return { wch }
            })

            worksheet['!cols'] = wsCols
            worksheet['!merges'] = this.merge
            return worksheet
        },
        filterWorkSheet: function () {
            // 一個查詢(過濾)一行， A欄->查詢名稱、B欄->查詢值
            const dataset = Object.values(this.filters).sort(
                (a, b) => a.seq - b.seq
            )
            const wsInit = {
                '!ref': `A1:B${dataset.length}`
            }
            let maxWidth
            const worksheet = dataset.reduce((ws, row, rowIndex) => {
                // A欄->查詢名稱
                let colLetter = 'A'
                let cellKey = `${colLetter}${rowIndex + 1}`
                let cell = row.title
                ws[cellKey] = {
                    t: 's',
                    v: cell
                }

                // B欄->查詢值
                colLetter = 'B'
                cellKey = `${colLetter}${rowIndex + 1}`
                cell = row.label || '-'

                /* 輸出Cell */
                ws[cellKey] = {
                    t: typeof cell === 'number' ? 'n' : 's',
                    v: cell
                }

                /* 計算此欄最大寬度 (自適應寬度) */
                const valStr = cell?.toString() || ''
                const doubleWord = valStr.match(/[\u4e00-\u9fa5]/g)?.length || 0
                const singleWord = valStr.length - doubleWord
                const wordWidth = doubleWord * 2 + singleWord + 4 // 4 是用來 padding
                if (maxWidth < wordWidth || !maxWidth) {
                    maxWidth = wordWidth
                }
                return ws
            }, wsInit)

            const wsCols = [{ wch: 40 }, { wch: maxWidth }]

            worksheet['!cols'] = wsCols
            return worksheet
        },
        descriptionWorkSheet: function () {
            // 備註一列一個 A欄->備註序列、B欄->備註內容
            const dataset = this.descriptions
            const wsInit = {
                '!ref': `A1:B${dataset.length}`
            }
            let maxWidth
            const worksheet = dataset.reduce((ws, row, rowIndex) => {
                // A欄->備註序列
                let colLetter = 'A'
                let cellKey = `${colLetter}${rowIndex + 1}`
                let cell = `註${rowIndex + 1}:`
                ws[cellKey] = {
                    t: 's',
                    v: cell
                }

                // B欄->備註內容
                colLetter = 'B'
                cellKey = `${colLetter}${rowIndex + 1}`
                cell = row

                /* *** Value處理  *** */
                cell = this.extractHtmlContent(cell)

                // 清除HTML空白格
                if (typeof cell === 'string')
                    cell = cell.replace(/&nbsp;/g, ' ')

                /* 輸出Cell */
                ws[cellKey] = {
                    t: typeof cell === 'number' ? 'n' : 's',
                    v: cell
                }

                /* 計算此欄最大寬度 (自適應寬度) */
                const valStr = cell?.toString() || ''
                const doubleWord = valStr.match(/[\u4e00-\u9fa5]/g)?.length || 0
                const singleWord = valStr.length - doubleWord
                const wordWidth = doubleWord * 2 + singleWord + 4 // 4 是用來 padding
                if (maxWidth < wordWidth || !maxWidth) {
                    maxWidth = wordWidth
                }
                return ws
            }, wsInit)

            const wsCols = [{ wch: 6 }, { wch: maxWidth }]

            worksheet['!cols'] = wsCols
            return worksheet
        },
        infoWorkSheet: function () {
            // 備註一列一個 A欄->備註序列、B欄->備註內容
            const dataset = this.infos
            const wsInit = {
                '!ref': `A1:B${dataset.length}`
            }
            let maxWidth
            const worksheet = dataset.reduce((ws, row, rowIndex) => {
                // A欄->資訊名稱
                let colLetter = 'A'
                let cellKey = `${colLetter}${rowIndex + 1}`
                let cell = row.title
                ws[cellKey] = {
                    t: 's',
                    v: cell
                }

                // B欄->備註內容
                colLetter = 'B'
                cellKey = `${colLetter}${rowIndex + 1}`
                cell = row.value

                /* *** Value處理  *** */
                cell = this.extractHtmlContent(cell)

                // 清除HTML空白格
                if (typeof cell === 'string')
                    cell = cell.replace(/&nbsp;/g, ' ')

                /* 輸出Cell */
                ws[cellKey] = {
                    t: typeof cell === 'number' ? 'n' : 's',
                    v: cell
                }

                /* 計算此欄最大寬度 (自適應寬度) */
                const valStr = cell?.toString() || ''
                const doubleWord = valStr.match(/[\u4e00-\u9fa5]/g)?.length || 0
                const singleWord = valStr.length - doubleWord
                const wordWidth = doubleWord * 2 + singleWord + 4 // 4 是用來 padding
                if (maxWidth < wordWidth || !maxWidth) {
                    maxWidth = wordWidth
                }
                return ws
            }, wsInit)

            const wsCols = [{ wch: maxWidth }, { wch: maxWidth }]

            worksheet['!cols'] = wsCols
            return worksheet
        }
    }
}
</script>
